<template>
  <div class="opage" ref="pageScrollBox">
    <headBar />
    <div class="bannerBoxvv">
      <div class="bannerBoxLeft">
        <h1>
          Ayudamos a tu negocio a ser más rentable
        </h1>
        <h2>
          Rakumart es la plataforma de importación “todo en uno” para abastecer
          tu negocio en el mercado mayorista más grande del mundo.
        </h2>
        <ul>
          <li>Nosotros gestionamos todo, desde fábrica hasta tu puerta</li>
          <li>Aumenta la rentabilidad y el tiempo para tu negocio</li>
        </ul>
        <button class="modelButton" @click="$fun.routerToPage('/register')">
          Regístrate ahora
        </button>
      </div>
      <div class="bannerBoxRight">
        <img
          :src="
            require('@/assets/foot-img/ventajas/Reduce-los-costes-en-producto-7.png')
          "
          alt=""
        />
      </div>
    </div>
    <div class="modelRow reduceCosts">
      <div class="modelBody">
        <img
          :src="require('@/assets/foot-img/ventajas/ventajas-rakumart-23.png')"
          alt=""
        />
        <div class="reduceCostsRight">
          <h1 class="modelH1">
            Reduce los costes en producto y aumenta la rentabilidad de tu
            negocio.
          </h1>
          <p class="modelP">
            Rakumart provee a sus clientes la posibilidad de abastecerse de los
            productos que necesitan a precios más económicos. Cotizamos los
            productos de tus pedidos directamente con fabricantes y proveedores,
            eliminando intermediarios y reduciendo costes.
          </p>
          <div class="availableProducts">
            <div class="opt">
              <span class="otitle">
                Millones
              </span>
              <span class="ocon">
                de productos disponibles
              </span>
            </div>
            <div class="opt">
              <span class="otitle">
                +100.000
              </span>
              <span class="ocon">
                proveedores verificados
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modelRow reduceShippingTimeAndCosts">
      <div class="modelBody">
        <div class="modelFontBox">
          <h1 class="modelH1">
            Compra a diferentes proveedores en un mismo pedido, reduce tiempo y
            costes de envío.
          </h1>
          <p class="modelP">
            Olvídate de hablar con varios proveedores a la vez y pagar varias
            importaciones. En Rakumart somos nosotros quién hacemos el trabajo
            sucio. Gestionamos todas las necesidades de tus productos y
            preparamos toda tu mercancía en un solo envío internacional.
          </p>
        </div>
        <img
          :src="
            require('@/assets/foot-img/ventajas/Compra-a-diferentes-proveedores-en-un-mismo-pedido-5.png')
          "
          alt=""
        />
      </div>
    </div>
    <div class="modelRow theWholeProcessIsInOnePlace">
      <div class="modelBody">
        <div class="modelFontBox">
          <h1 class="modelH1">
            Todo el proceso desde un mismo sitio.
          </h1>
          <p class="modelP">
            Sourcing, compra, personalización, servicios adicionales, control de
            calidad, logística. ¡Todo en uno! Nuestros clientes pueden disfrutar
            todo el proceso de importación sin moverse del sitio.
          </p>
          <button class="modelButton" @click="$fun.routerToPage('/register')">
            Regístrate ahora
          </button>
        </div>
        <ul class="platformQuote">
          <li>
            Encuentra o solicita la cotización de cualquier producto desde
            nuestra plataforma
          </li>
          <li>
            Gestiona la personalización para tu marca propia o solicita
            servicios adicionales extra
          </li>
          <li>
            La mercancía de todos los pedidos pasa por nuestro equipo de control
            de calidad en China
          </li>
          <li>
            Almacena e importa a tu ritmo, haciendo uso de nuestros almacenes
            sin coste añadido
          </li>
        </ul>
      </div>
    </div>
    <div class="modelRow infrastructure">
      <div class="modelBody">
        <div class="modelFontBox">
          <h1 class="modelH1">
            Rakumart te brinda toda su infraestructura en China para sacarle
            provecho con tu negocio.
          </h1>
          <p class="modelP">
            Equipo nativo en China, gran experiencia en sourcing y compra a
            fabricantes, logística en China y en España con más de 70.000m2 de
            almacenes en las empresas de nuestro grupo.
          </p>
          <p class="modelP">
            Aprovecha nuestra capacidad operativa, logística y almacenes en
            China sin costes añadidos, ponemos a tu disposición todos nuestros
            recursos.
          </p>
        </div>
        <img
          src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/641161912c438.png"
          alt=""
        />
      </div>
    </div>
    <div class="modelRow youControl">
      <div class="modelBody">
        <!--file: Gestionamos-completamente-toda-la-importacion-desde-fabrica-hasta-tu-puerta-3-1024x991.png -->
        <img
          src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/6411652348798.png"
          alt=""
        />
        <div class="modelFontBox">
          <h1 class="modelH1">Nosotros lo hacemos todo, tú lo controlas.</h1>
          <p class="modelP">
            Libérate de los dolores de cabeza, dedícale tu tiempo y esfuerzo a
            tu negocio. Nos encargamos de toda la gestión documentaria, trámites
            y procesos aduaneros, ¡déjalo en nuestras manos!
          </p>
          <p class="modelP">
            Controla la mercancía que dispones en almacén, accede a tu historial
            de facturas, prepara futuros pedidos y sigue todo el proceso de
            compra e importación desde tu panel de control.
          </p>
        </div>
      </div>
    </div>
    <div class="modelRow likeSiblings">
      <h1 ref="h1Box">
        “Trabajar con Rakumart es como tener tus ojos y manos en China sin
        moverte de la oficina”
      </h1>
    </div>
    <div class="modelRow afterSalesService">
      <div class="modelBody">
        <div class="modelFontBox">
          <h1 class="modelH1">
            Acompañamiento, asesoramiento, servicio de atención al cliente y
            posventa en español.
          </h1>
          <p class="modelP">
            Qué bueno es sentirse como en casa… En Rakumart, nuestros clientes
            pueden contar con acompañamiento, asesoramiento y servicio de
            atención al cliente y posventa en español.
          </p>
          <p class="modelP">
            Resuelve dudas, problemas o cualquier inconveniente que tengas de
            manera rápida y eficaz con nuestro equipo, en tu mismo idioma.
          </p>
        </div>
        <!-- file: asesoramiento-rakuamrt.png -->
        <img
          src="https://span-rakumart.oss-eu-central-1.aliyuncs.com/202303/64116e7fbea36.png"
          alt=""
        />
      </div>
    </div>
    <div class="modelRow qualityAssurance">
      <div class="qualityAssurancecon">
        <img src="../../assets/foot-img/ventajas/rakumart-espana.png" alt="" />
        <h1>Garantía de Rakumart España, 100% buenas experiencias.</h1>
        <p>
          Hacer tus compras e importaciones a través de Rakumart, ¡lo cambia
          todo! Nuestra sede en España se hace responsable de todas y cada una
          de las operaciones de nuestros clientes, benefíciate de la seguridad y
          garantía que esto representa.
        </p>
        <button class="modelButton" @click="$fun.routerToPage('/register')">
          Regístrate ahora
        </button>
      </div>
    </div>
    <footerBar />
  </div>
</template>
<script>
import headBar from "../../components/head/HomePageTop.vue";
import footerBar from "../../components/foot/Foot.vue";
export default {
  data() {
    return {
      elementList: [
        {
          name: "div",
          class: "icon1",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #89ACF4;border-radius: 50px;top: 100px;left: -268px;",
          dongHuaLieBiao: [
            {
              min: "200",
              max: "-100",
              danwei: "px",
              editStyle: "top",
            },
          ],
        },
        {
          name: "div",
          class: "icon2",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #DEF0FF;border-radius: 50px;top: 16px;left: -168px;",
          dongHuaLieBiao: [
            {
              min: "-168",
              max: "-110",
              danwei: "px",
              editStyle: "left",
            },
          ],
        },
        {
          name: "div",
          class: "icon3",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #DEFCF1;border-radius: 50px;top: 16px;left: -168px;",
          dongHuaLieBiao: [
            {
              min: "-50",
              max: "-80",
              danwei: "px",
              editStyle: "left",
            },
          ],
        },
        {
          name: "div",
          class: "icon4",
          style:
            "transition: .1s;width: 56px;height: 26px;position: absolute;background-color: #DEFCF1;border-radius: 50px;top: 130px;",
          dongHuaLieBiao: [
            {
              min: "-280",
              max: "-80",
              danwei: "px",
              editStyle: "left",
            },
          ],
        },
        {
          name: "div",
          class: "icon5",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #FFF0DC;border-radius: 50px;top: 166px;",
          dongHuaLieBiao: [
            {
              min: "107",
              max: "28.171",
              danwei: "px",
              editStyle: "left",
              maxJinDu: 0.01,
            },
          ],
        },
        {
          name: "div",
          class: "icon6",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #DEFCF1;border-radius: 50px;left: 544px;",
          dongHuaLieBiao: [
            { min: "-107", max: "-30", danwei: "px", editStyle: "top" },
          ],
        },
        {
          name: "div",
          class: "icon7",
          style:
            "transition: .1s;width: 26px;height: 56px;position: absolute;background-color: #DEFCF1;border-radius: 50px;left: 900px;",

          dongHuaLieBiao: [
            {
              min: "-160",
              max: "0",
              danwei: "px",
              editStyle: "top",
              // maxJinDu: 0.7, //动画执行到哪个进度 100% == 1
            },
          ],
        },
        {
          name: "div",
          class: "icon8",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #DEF0FF;border-radius: 50px;left: 900px;",

          dongHuaLieBiao: [
            {
              min: "380",
              max: "100",
              danwei: "px",
              editStyle: "top",
            },
          ],
        },
        {
          name: "div",
          class: "icon9",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #89ACF4;border-radius: 50px;left: 835px;",

          dongHuaLieBiao: [
            {
              min: "309",
              max: "121",
              danwei: "px",
              editStyle: "top",
            },
          ],
        },
        {
          name: "div",
          class: "icon10",
          style:
            "transition: .1s;width: 26px;height: 26px;position: absolute;background-color: #FFBA55;border-radius: 50px;top: 68px;",

          dongHuaLieBiao: [
            {
              min: "1250",
              max: "1014",
              danwei: "px",
              editStyle: "left",
            },
          ],
        },
      ],
    };
  },
  components: {
    headBar,
    footerBar,
  },
  computed: {},
  created() {},
  mounted() {
    this.generateElements();
    window.addEventListener("scroll", () => {
      this.setAnimate();
    });
  },
  methods: {
    generateElements() {
      this.elementList.forEach((element) => {
        let newele = document.createElement(element.name);
        newele.className = element.class;
        newele.style = element.style;
        this.$refs.h1Box.appendChild(newele);
      });
    },
    // 设置动画
    setAnimate() {
      if (!this.$refs.h1Box) {
        return false;
      }
      let dd = document.documentElement;
      let topNum = dd.scrollTop;
      // console.log(topNum);
      let small = 10;
      let big = 11;
      topNum = Math.max(topNum, small);
      topNum = Math.min(topNum, big);
      topNum = topNum - small;
      this.elementList.forEach((element) => {
        let jinDuBaiFenBi = topNum / (big - small); //计算出当前动画进行进度 值:0.12 == 12%
        element.dongHuaLieBiao.forEach((dongHuaItem) => {
          let jinDu =
            (Number(dongHuaItem.max) - Number(dongHuaItem.min)) * jinDuBaiFenBi;
          // console.log(element.class, jinDuBaiFenBi, jinDu);
          // if (dongHuaItem.maxJinDu) {
          //   //假如设置了最大进度,就获取每次滚动应该多运动多少再加上
          //   let newZhi = 1 / dongHuaItem.maxJinDu;
          //   jinDu = jinDu * newZhi;
          // }

          this.$refs.h1Box.getElementsByClassName(element.class)[0].style[
            dongHuaItem.editStyle
          ] =
            (Number(dongHuaItem.min) + Number(jinDu)).toString() +
            dongHuaItem.danwei;
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.opage {
  background-color: #f9f9f7;
}
.bannerBoxvv {
  margin-top: -20px;
  height: 700px;
  background-image: linear-gradient(to bottom left, #fef1ef, #e0efec);
  display: flex;
  justify-content: center;
  align-items: center;
  .bannerBoxLeft {
    width: 650px;
    height: 480px;
    padding: 50px;
    h1 {
      font-size: 46px;
      font-weight: 700;
      line-height: 1.2em;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      margin-bottom: 35px;
    }
    ul {
      li {
        &:before {
          background-image: url("../../assets/foot-img/ventajas/yuan.svg");
          background-size: 100%;
          width: 12px;
          height: 12px;
          display: inline-block;
          content: "";
          margin-right: 13px;
        }
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }
  .bannerBoxRight {
    height: 480px;
    width: 590px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.modelRow {
  .modelBody {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
  }
  .modelH1 {
    font-size: 31px;
    font-weight: 600;
    line-height: 1.4em;

    margin-bottom: 20px;
  }
  .modelP {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 14px;
  }
  .modelFontBox {
    padding: 50px;
  }
}
.modelButton {
  background-color: #1a289d;
  border-radius: 50px 50px 50px 50px;
  padding: 20px 40px;
  font-size: 16px;
  font-weight: 500;
  color: white;
}
.reduceCosts {
  background-color: white;
  padding: 80px 0 50px;
  img {
    width: 635px;
    height: 517px;
  }
  .reduceCostsRight {
    width: 583px;
    // height: 517px;
    padding: 30px;
    .availableProducts {
      padding-top: 40px;
      display: flex;
      .opt {
        display: inline-block;
        border-left: solid 5px #a5eed3;
        padding-left: 20px;
        width: 261px;
        > span {
          display: block;
        }
        .otitle {
          font-size: 28px;
          font-weight: 600;
          line-height: 1.4em;
        }
        .ocon {
          font-size: 18px;
          font-weight: 400;
          line-height: 33px;
          margin-bottom: 14px;
        }
      }
    }
  }
}
.reduceShippingTimeAndCosts {
  padding: 20px 0 80px;
  background-color: white;
  .modelFontBox {
    flex: 1;
    height: 490px;
    padding-right: 10px;
  }
  img {
    width: 606px;
    height: 490px;
  }
}
.theWholeProcessIsInOnePlace {
  padding: 150px 0;
  background-color: #f0f6f5;
  .modelFontBox {
    flex: 1;
    .modelH1 {
      font-size: 50px;
      font-weight: 700;
      line-height: 1.2em;
      margin-bottom: 20px;
    }
    .modelP {
      margin-bottom: 35px;
    }
  }
  .platformQuote {
    width: 520px;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        $size: 25px;
        width: $size;
        height: $size;
        margin-right: 15px;
        display: block;
        content: "";
        background: url("../../assets/foot-img/ventajas/gou.png") no-repeat;
        background-size: 100%;
        margin-top: 9px;
      }
      font-size: 20px;
      font-weight: 600;
      line-height: 1.6em;
    }
  }
}
.infrastructure {
  background-color: white;
  padding: 50px 0;
  .modelFontBox {
    flex: 1;
    padding: 30px;
    height: 571px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  img {
    width: 604px;
    height: 571px;
  }
}
.youControl {
  background-color: white;
  img {
    width: 620px;
    height: 600px;
  }
  .modelFontBox {
    padding: 30px;
    flex: 1;
  }
}
.likeSiblings {
  height: 525px;
  background-color: #fffdf8;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  h1 {
    position: relative;
    display: block;
    font-size: 41px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    line-height: 1.2em;
    letter-spacing: 0px;
    width: 705px;
    text-align: center;
  }
}
.afterSalesService {
  background-color: white;
  padding: 80px 0;
  .modelFontBox {
    flex: 1;
    height: 495px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
  }
  img {
    width: 645px;
    height: 495px;
  }
}
.qualityAssurance {
  .qualityAssurancecon {
    margin: 0 auto;
    width: 780px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 697px;
  }
  img {
    $size: 85px;
    width: $size;
    height: $size;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 47px;
    font-weight: 700;
    line-height: 1.1em;
    margin-bottom: 20px;
    text-align: center;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 35px;
    text-align: center;
  }
}
</style>
